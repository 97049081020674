@import "../../theme/core.less";

.limited-access-modal {
    max-width: 796px;

    .ant-modal-close {
        top: 24px;
        right: 40px;
    }
    .ant-modal-content {
        padding: 32px;
        padding-top: 60px;
        
        .ant-modal-footer {
            border-top: 1px solid @el-lines-color;
            padding: 24px 0 0 24px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .content {
        padding-top: 40px;
        border-top: 1px solid @el-lines-color;
        display: flex;
        flex-direction: column;
        align-items: center;

        .content-text {
            .h5();
            color: @text-primary-color;
            text-align: center;
            max-width: 500px;
            margin: 32px 0;
        }
    }
}

