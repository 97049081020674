@import "../../../../../../../theme/default.less";

.modal-select-input{
  .ant-tag-close-icon{
    margin-left: 12px;
    svg{
        fill: #B7B9C0;
    }

  }

}

