@import "../../theme/core.less";

.cardTag {

  &__text > p {
    margin: 0 !important;
  }

  &__text {
    background-color: @brand-primary-color;
    border-radius: 20px;
    font-family: @font-content;
    font-style: normal;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: @text-secondary;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    width: fit-content;
    height: fit-content;
    .media-tablet-only({
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    });

    .media-mobile-only({
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 16px;
    });

    &--green {
      background-color: @status-green-color;
    }

    &--red {
      background-color: @error-label-color;
    }

    &--yellow {
      background-color: @colors-baku
    }

    &--gray {
      color: @text-secondary;
      background-color: @card-background-gray;
    }

    &--orange {
      color: @text-secondary;
      background-color: @card-background-orange;
    }

    &--violet {
      color: @text-secondary;
      background-color: @card-background-violet;
    }

    &--purple {
      color: @text-secondary;
      background-color: @card-background-purple;
    }

    &--transparent {
      color: @el-gray-color;
      background-color: @card-background-light;
      border: 1px solid @el-border-color;
    }

    &--white {
      color:@text-primary-color;
      background-color: @bg-secondary;
    }

    &--lightBlue{
      background-color:@colors-helsinki;
      color: @text-secondary;
    }
  }
}

