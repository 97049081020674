.title-button{
    &__title{
        margin-bottom: 8px;
        font-size: 14px;
    }
    &__text{
        display: flex;
        flex-direction: column;
       
    }
    &__buttons{
        display: flex;
        flex-direction: row; 
        grid-template-columns: auto;  
        gap: 8px; 
    }
    &__buttonText{
        font-size: 14px;
        background-color: #F0F7FC;
        border-radius: 5%;
        color:#70A4CF !important;
        padding: 5px 10px !important;
        display: flex;
        align-items: center;
    }
}

