@import "../../theme/core.less";

.pagination-v2 {
    &__body{
        font-family: @font-content;
        min-width: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
    }


    &__left-total,
    .ant-pagination {
        color: @text-secondary-color;
    }

    &__left-total {
        .main-text();

        &--hidden > span {
            display: none;
        }
    }

    .ant-pagination {
        display: flex;
        align-items: center;

        &::before {
            order: -2;
            content: attr(data-options-label);
            font-size: 16px;
            line-height: 20px;
        }

        &-item {
            display: none !important;
        }

        &-options {
            order: -1;

            .ant-select-selector {
                display: flex;
                align-items: center;
                border: none !important;
                box-shadow: none !important;
            }

            .ant-select-dropdown {
                padding: 0;
                border-radius: 8px;

                .ant-select-item-option {
                    padding-left: 16px;
                }

                .ant-select-item-option-active {
                    // hover
                    background-color: @primary-color-o-10;
                }

                .ant-select-item-option-selected {
                    // selected
                    background-color: initial;
                    color: @primary-color;
                }
            }
            .ant-select-selection-item {
              font-size: 16px;
              line-height: 20px;
            }
        }

        &-total-text {
          margin: 0 0 0 44px;
          height: 32px;
          line-height: 30px;
          vertical-align: middle;
        }


        &-prev, &-next {
            margin-left: 20px !important;
        }

        &-prev[aria-disabled="true"],
        &-next[aria-disabled="true"] {
            button {
                color: @bc-color;
            }
        }

        &-prev[aria-disabled="false"],
        &-next[aria-disabled="false"] {
            button {
                color: @primary-color;
            }
        }

        &-jump-next, &-jump-prev {
            display: none;
        }
    }

    &__button {
        margin: 42px auto 0;
    }
}

