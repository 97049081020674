@import '../../theme/core.less';

.ant-input.input-v2 {
    height: @inp-v2-height;
    padding: @inp-v2-padding;
    border: @inp-v2-border;
    border-radius: @inp-v2-border-radius;

    font-family: @inp-font-family;
    font-style: @inp-font-style;
    font-size: @inp-v2-font-size;
    line-height: @inp-v2-line-height;
}

.input-v2 {
    &&__form-input {
        .ant-form-item-label label {
            color: @text-secondary-color;
        }
    }
}

.input-v2__textarea {
    & > textarea {
        padding: 8px 16px 24px;
    }

    & > textarea.ant-input-borderless {
        padding: 0;
        height: fit-content;
        overflow: hidden;
        resize: none;
    }
}
.input-v2__textarea.ant-input.input-v2 {
    height: auto !important;
}

