@import '../../theme/core.less';


.page-wrapper {
    background: @bg-primary;
    &::-webkit-scrollbar{
        display: none;
    }

    .media(@breakpoint-mobile-medium, {
        background: @bg-secondary;
    });

    &__container {
        height: calc(100% - @nav-height);
        display: flex;
        position: relative;
        &::-webkit-scrollbar{
          display: none;
        }
    }

    &__content {
        width: 100%;
        position: relative;
        transition: all 0.3s, outline 0s;
        overflow: scroll;

        & > div {
            max-width: 1920px;
            margin-left: auto;
            margin-right: auto;
        }

        &::-webkit-scrollbar{
          display: none;
        }
    }

    .mobile-nav-drawer {
        position: absolute;

        .ant-drawer-body {
            padding: 0;
        }

         transition: all 0.3s, outline 0s;

        &.small {
            min-width: 60px !important;
            width: 60px !important;
            transition: all 0.3s, outline 0s;
        }

        &.ant-drawer-open .ant-drawer-mask {
            background: rgba(228, 231, 242, 0.4);
            backdrop-filter: blur(10px);
        }

    }
}

