@import "../../theme/default.less";

.file-dragger {
  transition: all 1s;

  & .ant-upload-list-item-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  & .ant-upload-list-item {
    padding: 16px 14px;
    background: @file-upload-info;
    border-radius: 4px;
    color: @primary-color;
  }

  & .anticon-file-image {
    font-size: 20px !important;
    color: @primary-color !important;
  }
}
.img-crop-modal .img-crop-container {
  height: 65vh !important;
}
.ant-modal {
  top: 50px !important;
}
