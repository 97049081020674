@import "../../../../../../theme/default";

.educationLevels {
  &__button {
    margin-left: auto;
  }
  .ant-dropdown-open {
    background: @dropdown-open;
  }

  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
}

