@import "../../../../../../../../../../../../theme/default.less";

.part-selector {
  padding: 16px 24px 0;
  border: 1px solid @border-color;
  border-radius: 4px;
  margin-bottom: 16px;
  &__{
    &header {
      display: flex;
      justify-content: space-between;
      > * {
        flex: 1;
      }
    }
  }
  &__drag-container {
    text-align: center;
    cursor: grab;
    display: flex;
    justify-content: center;
  }
  &__remove-container {
    text-align: right;
    cursor: pointer;
  }
}
