@import "../../../../../../theme/default";
.direction-of-study{
  .button-v2.ant-btn:not(.ant-btn-block) {
    max-width: 270px;
  }
  & .general-table-template__search-panel {
    justify-content: space-between;
  }
  .ant-dropdown-open {
    background: @dropdown-open;
  }
  .ant-input-number-handler-wrap{
    display: none;
  }
  .ant-input-number{
    width: 80px;
  }
  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
}

