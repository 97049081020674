.question-modal{

    &__footer{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }
    &__footer {

        .ant-form-item {
            margin-bottom: 0;
        }
    }
  }

