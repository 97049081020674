.review-modal{
  &__footer{
    display: flex !important;
    justify-content: flex-end;
  }
  &__footer {
    .ant-form-item {
      margin-bottom: 0;
    }
    & > button:not(:last-child) {
      margin-right: 16px;
    }
  }
}

