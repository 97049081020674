@import "../../../../../../../../../../../../theme/default.less";

.sortable {
  &__ {
    &item {
      margin: 10px 0;
      border: 1px solid rgba(255,0,0,.3);
      padding: 10px;
      background: #eee;
      &--dragged {
        transform: translateX(20px);
      }
    }
  }
}
