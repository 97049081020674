@import "./mixins/typography.less";

// fonts
@font-headers: 'Montserrat', sans-serif;
@font-content: 'IBM Plex sans', sans-serif;

// generics
@primary-color: #0071CE;
@primary-color-o-10: #0071CE10;
@primary-color-o-40: #0071CE40;
@primary-color-o-60: #0071CE60;
@text-primary: #333;
@text-secondary: #FFFFFF;
@bg-primary: #F5F6F8;
@bg-secondary: #FFFFFF;
@bc-color: #12152A;
@bc-color-dd: #D3DDFF;
@bc-color-0-40: #12152A40;
@bc-color-0-70: #12152A70;
@modal-close: #374957;
@modal-success-o-60: #15B012CC;
@error-label-color: #EB5757;
@modal-error-o-60: #EB575799;
@separator-color: #71737F;
@separator-color-v2: #E4E7F2;
@border-color: #E4E7F2;
@login-separator-line: @border-color;
@label-color: #6F7C98;
@login-label: @label-color;
@message-error-bg: #FF5F5F;
@col-black: #000000;
@message-warning-bg: linear-gradient(90deg, #FAA05A 0%, #FD9A1B 100%);

// color pallete v2
@brand-primary-color: #0071CE;
@brand-prime: #0071CE;
@brand-secondary-color: #15B012;
@brand-second: #15B012;
// text
@text-primary-color: #3B4168;
@text-secondary-color: #6F7C98;
@text-help-color: #909EBB;
@text-link-color: #0071CE;
@text-link-visited-color: #7D6FE1;
@text-footer: #ffffff;
@text-partner: #909EBB;
// elements
@el-white-color: rgb(255, 255, 255);
@el-black-color: #12152A;
@el-bg-color: #F5F6F8;
@el-gray-color: #909EBB;
@el-lines-color: #E4E7F2;
@el-disabled-color: #BDBDBD;
@el-active-color: #99C6EB;
@el-active-2-color: #F5F5F7;
@el-hover-color: #005EAC;
@el-hover-2-color: #F5FBFF;
@el-border-color: #E4E7F2;
@el-footer-color: #ffffff;
// status
@status-green-color: #73D071;
@status-green-2-color: #85DB83;
@status-warning-color: #F7B055;
@status-error-color: #EB5757;
@status-error-2-color: #F39A9A;
// colors
@colors-tokyo: #07B4CF;
@colors-baku: #F3B944;
@colors-lisbon: #E26C3C;
@colors-helsinki: #73AAFF;
@colors-prague: #7B61FF;

// paddings & margins
@step-0: 4px;
@step-1: 8px;
@step-10: 12px; // @step-1 + @step-0
@step-2: 16px;
@step-20: 20px; // @step-2 + @step-0
@step-3: 24px;
@step-30: 28px; // @step-3 + @step-0
@step-4: 32px;
@step-40: 36px; // @step-4 + @step-0
@step-41: 40px; // @step-4 + @step-1
@step-5: 48px;
@step-50: 52px; // @step-5 + @step-0
@step-51: 56px; // @step-5 + @step-1
@step-6: 64px;
@step-61: 72px; // @step-6 + @step-1

// page title
@page-title-size: 28px;
@page-title-weight: 700;
@page-title-line-height: 36px;
@page-title-margin-bottom: 18px;

// scroll, ::-webkit-scrollbar
@scroll-primary: #8E9FAD;
@scroll-color: #DAE4EE;

// navigation
@nav-height: 64px;
@nav-margin-bottom: @step-1;

// previous page panel
@prev-panel-height: 92px;

// fonts
@font-family: @font-headers;

// tabs
@tabs-bg-color: @bg-primary;
@tabs-border-radius: 30px;
@tabs-border-color-45: rgba(51, 51, 51, 0.45);
@tabs-active-tab-color: @text-primary;
@font-size: 20px;
@font-style: normal;
@font-weight: 400;
@line-height: 24px;
@letter-spacing: 0em;

// buttons
@btn-primary-color: @primary-color;
@btn-font: @font-content;
@btn-line-height: 20px;
@btn-button-height: 58px;
@btn-border-radius: 10px;

// buttons v2
@btn-v2-font-family: @font-content;
@btn-v2-height: 44px;
@btn-v2-max-width: 215px; //С этим лучше что-то сделать. Зачем задавать максимальную ширину всем кнопкам?
@btn-v2-border-radius: 4px;
@btn-v2-font-size: 16px;
@btn-v2-font-weight: 500;
@btn-v2-line-height: 20px;
@btn-v2-color-primary: @primary-color;
@btn-v2-color-secondary: @text-secondary;

// inputs
@inp-font-family: @font-content;
@inp-border-radius: 10px;
@inp-font-size: 20px;
@inp-font-style: normal;
@inp-font-weight: 400;
@inp-line-height: 26px;
@inp-letter-spacing: 0em;
@inp-text-align: left;

// inputs v2
@inp-v2-height: 40px;
@inp-v2-border: 1px solid @border-color;
@inp-v2-border-radius: 4px;
@inp-v2-padding: 12px 16px;
@inp-v2-font-size: 16px;
@inp-v2-line-height: 20px;

// heatmap
@heatmap-width: 960px;

// modal upload
@file-upload-info: #F5FBFF;

// dropdown
@dropdown-open: #E0F1FF;

//course dropdown
@box-shadow-dropdown-course: #0065dd0d;
@col-border-btn-course: rgba(255, 255, 255, 0.24);

//card tag
@card-background-light: #FBFDFF;
@card-background-gray: #909EBB;
@card-background-orange: @colors-lisbon;
@card-background-violet: @colors-prague;
@card-background-purple: @colors-helsinki;

// article-card
@col-bg-article-card: #3B4168;

//play pause button
@bg-video-button: rgba(245, 246, 248, 1);

//questions component
@col-ques-border-color: #6F7C98;

//admin courses
@col-text-select: #12152A;

// education-form-modal
@bg-col-education-form-modal: rgba(0, 0, 0, 0.05);

// notification auth
@bg-col-notification-auth: rgba(0, 0, 0, 0.39);

// courses detail page faq
@col-bg-fag: #fafafa00;

// review
@bg-review: #F5FBFF;


//slider:
@disabled-button: #E4E7F2;
@active-button: #0071CE;

//error page
@text-dark-color: #3B4168;
@col-shadow-text: rgba(0, 0, 0, 0.03);

// course digital professions program
@bg-program-digital: linear-gradient(85.48deg, #065AA6, #E83A54);
@bg-program-digital-mobile: linear-gradient(52.32deg, #065AA6 4.9%, #E83A54 100.51%);

// course program
@bg-course-programs: #3B4168;
@col-border-program: #6F7C98;
@col-border-icon: #ffffff;

// my course card
@col-text-date: @text-help-color;
//employment
@bg-color-employment: #ECF0FF;
//promo card
@green-promo-color: #37D062;

//  footer
@bg-footer-default: rgba(228, 231, 242, 0.5);

//filters tags
@tags-font-weight: 400;

// breakpoints
@breakpoint-laptop-large: 1920px; // by design desktop width
@breakpoint-laptop-medium: 1600px; //key breakpoint
@breakpoint-laptop-small: 1200px;

@breakpoint-tablet-last: 1199px; // last breakpoint
@breakpoint-tablet-large: 1024px; // by design tablet width
@breakpoint-tablet-medium: 992px;
@breakpoint-tablet-small: 768px;

@breakpoint-mobile-last: 767px; // last breakpoint
@breakpoint-mobile-large: 576px; //key breakpoint
@breakpoint-mobile-medium: 480px;
@breakpoint-mobile-small: 375px; // by design phone width

/*
    antd breakpoints
    xs: '480px',
    sm: '576px',

    md: '768px',
    lg: '992px',

    xl: '1200px',
    xxl: '1600px',
*/

.media(@size, @rules) {
    @media screen and (max-width: @size) { @rules(); }
}
.media-min(@size, @rules) {
    @media screen and (min-width: @size) { @rules(); }
}
/* FROM 340 TO 767 */
.media-from-zero-to-smallest(@rules){
    @media screen and (min-width: 340px) and (max-width: 767px) { @rules(); }
}
/* FROM 768 to 1600 */
.media-tablet-and-m-laptop(@rules) {
    @media screen and (min-width: (@breakpoint-tablet-small - 1)) and (max-width: @breakpoint-laptop-medium) { @rules(); }
}

/* FROM 0 to 767 */
.media-mobile-only(@rules) {
    @media screen and (max-width: @breakpoint-tablet-small) { @rules(); }
}

/* FROM 768 to 1199 */
.media-tablet-only(@rules) {
    @media screen and (min-width: @breakpoint-tablet-small) and (max-width: (@breakpoint-laptop-small - 1)) { @rules(); }
}

/* FROM 1200 to infinity */
.media-laptop-only(@rules) {
    @media screen and (min-width: @breakpoint-laptop-small) { @rules(); }
}
