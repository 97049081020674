@import "../../../../../../../../theme/default.less";

.tag-modal {
  padding: 32px;

  &__action {
    margin-top: 65px;
    display: flex;
    justify-content: flex-end;

    & button:not(:last-child) {
      margin-right: 16px;
    }
  }
  
  &__image {
    text-align: center;
  }

  &__text {
    margin: 40px auto 0;
    max-width: 400px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  &__input-wrapper {

    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: @label-color;
      margin-bottom: 0;
    }
  }

  &__asteriks {
    vertical-align: super;
    margin-left: 4px;
  }

  &__error {
    color: @status-error-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  
  &__tooltip {
    display: block;
  }
}
