.all-information-review{
    &__desc{
        flex-wrap: wrap;
    }
    &__dates{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 12px;
    } 
    &__course-duration{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 12px;
    }
    &__aboutCourse{
       display: grid;
       grid-template-columns: 1fr 1fr;
       justify-content: space-between !important;
    }
    &__directionEdu{
      display: flex;
      flex-direction: column;
      gap:12px;
    }
    &__aboutEdu{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 24px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 24px 0;
    }
    &__requirements{
        display: flex;
        flex-direction: column;
    }
}

