@import '../../theme/core.less';
.under-construction {
  &__ {
    &title {
      font-size: 18px;
      font-family: @font-family;
      font-weight: 500;
      color: @text-primary-color;
      margin-top: 40px;
    }
  }
}

