@import "../../../../theme/core.less";

.dragger-file-upload{
  transition: all 1s;
 
  &__dragger {
    border: 2px dashed #E4E7F2 !important;
    background: none;
    min-height: 80px;
    height: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;

    &--hidden {
      display: none;
    }
    &--error {
      border: 2px dashed #F39A9A !important;
      background: #FFF7F7;
    }
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
    justify-self: center;
  }


  &__dragger-body {
    display: flex;
    grid-gap: 40px;
    align-items: center;
    justify-content: center;

    >p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: @text-secondary-color;
      >span{
        color: @text-link-color;
      }
    }

    &--error {
      > p {
        color: @status-error-color;
      }
    }
  }

}
.img-crop-modal .img-crop-container {
  height: 65vh !important;
}
.ant-modal {
  top: 50px !important;
}



