@import "../../../../../../../theme/core";
.modal-create-education-form {
  &__icons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 60px));
    grid-gap: 25px;
  }
  &__icon {
    padding: 12px;
    width: 60px;
    height: 60px;
    transition:  0.4s;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    & > button:not(:last-child) {
      margin-right: 24px;
    }
    & > .ant-form-item {
      margin: 0 ;
    }
  }
  .ant-modal-body {
    margin-bottom: 0 ;
  }
  &__icon-wrapper {
    cursor: pointer;
    position: relative;
    display: flex;
  }
  &__icon-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    align-items: center;
    justify-content: center;
    display: none;
    border: 2px solid @primary-color;

    &--active {
      display: flex;
      background: @bg-col-education-form-modal;
      z-index: 2;
    }
  }
  &__icon-wrapper--active &__icon {
    transform: scale(1.3);
  }
}
