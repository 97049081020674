@import "../../../../../../../theme/default.less";


.modal-partners{

  &__head-inputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-top: 18px;
    padding-bottom: 24px;
  }
  &__inputs-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__add-more-btn{
    >div{
      margin-right: 0;
    }
  }
}

