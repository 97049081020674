@import '../../theme/core.less';

.table-v2 {
  .ant-table {
    border-radius: @step-2;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: @step-2;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: @step-2;
  }

  .ant-table-tbody > tr > td {
    font-size: 16px;
    line-height: 20px;
    color: @text-primary-color;
  }

  .ant-table-thead > tr > th {
    background-color: @bg-secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: @text-secondary-color;
    &::before {
      display: none;
    }
  }
}

