@import "../../../../../../../../theme/default.less";

.promo-modal{
  &__footer{
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  &__option{
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 15px;
    color: @text-primary-color;
    font-family: @font-content;
  }
  &__color{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 10px;
  }
}

