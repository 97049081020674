@import "../../../../../../theme/default";

.evaluation-criteria {
  &__button {
    margin-left: auto;
  }

  .ant-dropdown-open {
    background: @dropdown-open;
  }

  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
  &__status {
    color: @el-disabled-color;

    &--active {
      color: @status-green-color;
    }
  }
}

