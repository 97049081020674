@import "../../theme/core";

.menu-manual {
  padding: 12px 16px;
  list-style: none;
  background: @bg-secondary;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(@col-black, 0.05);
  & li > button {
    color: @text-primary-color;
    cursor: pointer;
    padding: 0;
    background: transparent;

    &:hover {
      color: @text-link-color;
      background: transparent;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__delete:hover {
    color: @status-error-color !important;
  }
}
