@import "../../../../../../../../theme/core.less";
.create-post{
  &__checkbox{
    .ant-checkbox-wrapper{
      > span{
        color: #3B4168;
        font-family: @font-content;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    display: flex;
    padding: 0 20px;
    align-items: center;
    border-radius: 4px;
    background:  #F5FBFF;

  }
  &__saveCancel{
    display: flex;
  }
}
