@import '../../theme/core.less';

.search-input {
  display: flex;
  align-items: center;
  padding: 9px 16px;
  background: #FFFFFF;
  border: 1px solid @el-border-color;
  border-radius: 4px;
  min-width: 329px;
  transition: all .3s;
  max-width: 329px;
  width: 100%;

  &__input {
    outline: none;
    border: none;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: @text-primary-color;



    &::placeholder {
      color: @text-help-color;
    }
  }

  &__icon {
    display: inline-block;
    margin-left: 12px;
  }

  &__clear {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  &__search {
    width: 17px;
    height: 17px;
  }
}

