.modal-partners {
    &__partners-item {
        border-radius: 8px;
        border: 1px solid #E4E7F2;
        width: 100%;
        margin: 20px 0;
        padding: 20px;
    }

    &__file-name-area {
        margin-top: 24px !important;
    }

    .title-text__label{
        margin-top: 24px;
    }
}

