@import '../../../../theme/default';

.admin-courses {
  margin-top: 48px;
  position: relative;
  margin-bottom: 48px;
  & .general-table-template__search-panel {
    flex-direction: column;
    align-items: flex-start;
  }
  &__button-add {
    position: absolute !important;
    right: 0;
    &--up{
      top: -196px;
      right: 0;
    }
  }

  &__field {
    & .ant-input, & .ant-btn{
      height: 40px !important;
    }
  }
  .ant-dropdown-open {
    background: @dropdown-open;
  }

  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &__status {
    color: @el-disabled-color;
    &--published {
      color: @status-green-color;
    }
    &--agreed {
      color: @status-warning-color;
    }
  }
  .ant-input-number{
    width: 80px;
  }
  .ant-input-number-handler-wrap{
    display: none;
  }
}

