@import "../../../../../../../../../../theme/default.less";

.blog-article {
  &__text-editor {
    display: flex;
    flex-direction: column-reverse;

    .ql-container.ql-snow {
      border-top: 1px solid @border-color !important;
    }
  }
  &__divider {
    &.ant-divider-horizontal.ant-divider-with-text {
    color: @text-primary-color;
    }
  }
  &__buttons {
    display: flex;
    justify-content: stretch;
    padding: 20px;
    gap: 13px;
    background-color: @el-bg-color;
    border-radius: 4px;

    > * {
      flex: 1;
    }
    > .ant-btn {
      border: 0;
      background-color: @colors-helsinki;
    }
  }
}
