@import "../../../../theme/core";

.courses-filters {
  margin-top: 24px;
  display: flex;

  & .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 10px;
  }
  & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 36px;
  }

  & .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    padding-top: 2px;
  }

  & .ant-select-selection-placeholder{
    text-overflow: inherit;
    overflow: visible;
  }

  & .ant-select {
    margin: 0 15px 0 0;
  }

  & .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 54px;
  }
  & .ant-select-selection-placeholder{
    color: @col-text-select;
  }
  &__select {
    max-width: 225px;

    & .ant-select-selector {
      min-width: 187px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
      font-weight: 400;
      font-size: 14px;
      line-height: 50px;
      border-color: @bg-secondary !important;
      border-radius: 10px;

      .ant-select-selection-search-input {
        height: fit-content !important;
      }
    }

  }

  &__btn-clear {
    display: none !important;
    color: @text-secondary-color !important;
    margin-right:24px;

    &:hover {
      background: @bg-secondary !important;
    }
    &--show {
      display: block !important;
    }
  }

  &__clear-icon {
    width: 20px;
    height: 20px;
  }
}

