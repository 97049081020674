.blog-advertising {
  margin-bottom: 40px;
  &__icon {
    margin-right: 14px;
  }
  &__tab {
    display: flex;
    align-items: center;
  }
}
