@import '../../../../../../../../../../theme/core.less';

.recommendation {
  &__courses-item {
    display: flex;
    margin-bottom: 24px;

    .ant-form-item {
      margin: 0;
      width: 100%;
    }
  }

  &__icon-delete {
    font-size: 12px;
    transform: rotate(45deg);
    line-height: 10px;
    height: 10px;
    align-self: flex-end;
    color: @el-disabled-color !important;
    margin-bottom: 4px;
    margin-left: 16px;
  }

  &__course-add-button {
    margin-left: auto;
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #3B4168;
  }
}
