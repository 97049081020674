@import "../../../../../../../theme/default.less";


.modal-teachers{
  &__inputs-block{
    display: grid;
    grid-gap: 24px;
  }
  &__head-inputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-top: 18px;
    padding-bottom: 24px;
  }
  &__add-more-btn{
    >div{
      margin-right: 0;
    }
  }
}
