@import "../../../../../../../theme/default.less";

.modal-program{

  &__inputs-block{
    display: grid;
    grid-gap: 24px;
  }
  &__head-inputs{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 24px;
    margin-top: 18px;
    padding-bottom: 24px;
  }
  &__input-items{
    display: flex;
    grid-template-columns: 1fr 10px;
    grid-gap: 15px;
  }
  &__description{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
  &__inputs{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 24px;
    >div{
      width: 100%;
    }
  }
  &__item-close{
    font-size: 12px;
    transform: rotate(45deg);
    line-height: 10px;
    height: 10px;
    align-self: flex-end;
    color: #BDBDBD;
    margin-bottom: 4px;
    svg{
      fill: #BDBDBD;
    }
  }
  &__add-more-btn{
    >div{
      margin-right: 0;
    }
  }

  &__date-piker {
    .ant-picker-input {
      width: 114px;
    }
  }
  .ant-upload.ant-upload-drag{
    margin-top: 10px !important;
  }
}
