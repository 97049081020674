.assignment-role-modal {
  &__footer {
    display: flex;
    justify-content: flex-end;
    & .ant-form-item {
      margin: 0 !important;
    }
    & > button:not(:last-child) {
      margin-right: 24px;
    }
  }
}
