
@import '../../../../../../theme/default';

.questions {
    margin-top: 48px;
    position: relative;
   
    &__button {
      margin-left: auto;
    }

  &__select {
    margin-left: 24px !important;
    max-width: 195px;
    & .ant-select-selector {
      min-width: 187px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border-color: @bg-secondary !important;

      .ant-select-selection-search-input {
        height: fit-content !important;
      }
    }

  }

  &__btn-clear {
    display: none !important;
    color: @text-secondary-color !important;
    margin-right:24px;

    &:hover {
      background: @bg-secondary !important;
    }
    &--show {
      display: block !important;
    }
  }

  &__clear-icon {
    width: 20px;
    height: 20px;
  }
  .ant-dropdown-open {
    background: @dropdown-open;
  }

  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }

    &__status {
      color: @el-disabled-color;
  
      &--active {
        color: @status-green-color;
      }
  }
}
