@import "../../../../../../../theme/core";
.form-ads {
  &__field-list-item {
    display: flex;
    margin-bottom: 24px;
    .ant-form-item {
      margin: 0;
      width: 100%;
    }
  }

  &__icon-delete {
    font-size: 12px;
    transform: rotate(45deg);
    line-height: 10px;
    height: 10px;
    align-self: flex-end;
    color: @el-disabled-color !important;
    margin-bottom: 4px;
    margin-left: 16px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    & button:first-child {
      margin-right: 16px;
    }
  }

  &__item-add {
    margin-left: auto;
  }
  .ant-divider-horizontal{
    margin: 12px 0;
  }
  &__ads-banner{
    .ant-upload p{
      width: 70% !important;
    }
    .ant-form-item-label > label{
      color: @text-dark-color !important;
      font-weight: 400 !important;
      font-size: 16px !important;
    }
    .ant-upload-list-item-name{
      width: 150px !important;
    }
    .special-file-upload .ant-upload-list-item{
      left: 291px !important;
    }
  }
}

