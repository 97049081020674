@import '../../../../theme/core.less';

.title-text {
  &__label {
    font-size: 14px;
    color: @label-color;
    margin-bottom: 8px;
  }
  &__text {
    font-size: 16px;
    margin-bottom: 0;
  }
  &__link{
    color: @primary-color;
    font-size: 16px;
    text-decoration: underline;
  }
}

