@import "../../theme/core.less";

.footer {
  color: @text-dark-color;
  background-color: @bg-footer-default;
  padding: 32px 60px 0;

  ul {
    list-style-type: none !important;
  }

  li, a {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-family: @font-content;
    display: flex;
  }
  &__logo{
    width: 133px;
    height: 34px;
  }
  &__data {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    column-gap: 24px;
    width: 100%;
    row-gap: 14px;
  }

  &__list-heading {
    font-family: @font-headers;
    color:@text-dark-color;
    font-size: 14px;
    line-height: 16px;
    align-self: start;
    opacity: 32%;
    margin-bottom: 16px;
    font-weight: 600;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: @text-help-color;

    a {
      display: inline-flex;
    }
  }

  &__social-media {
    display: flex;
    align-items: center;

    li {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 17.5px;

      }
    }
  }

  &--dark {
    background-color: @bg-course-programs;
    color: @text-footer;
    padding: 32px 221px 0;

    h3 {
      color: @text-footer;
    }

    svg {
      color: currentColor;
    }

    .ant-divider-horizontal {
      background-color: @el-footer-color;
      opacity: 0.05;
    }

    .media-tablet-only({
      padding: 20px 60px 0 !important;
    });
  }

  .media-tablet-only({
    &__data {
      grid-template-columns: auto;
      justify-content: center;
      align-items: center;
    }
  });

  .media-mobile-only({
    padding: 20px 24px 0 !important;
    &__bottom {
      flex-direction: column;

      p {
        margin-bottom: 10px !important;
      }

      a {
        margin-bottom: 0
      }
    }
  });
}


