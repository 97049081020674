.modal-course-review{
  min-height: 208px !important;
    &__review{
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
    }
   .file-name-area {
     margin: 12px 0;
   }
}

