@import "../../../../../../../theme/default.less";

.modal-change-status {
  &__actions {
    display: flex;
    justify-content: flex-end;

    & > button:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__image {
    text-align: center;
  }

  &__text {
    margin: 40px auto 0;
    max-width: 400px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
}

