@import '../../../../../../theme/default';
.advertising {
  &__button-add {
    margin-left: auto;
  }

  &__status {
    color: @el-disabled-color;

    &--active {
      color: @status-green-color;
    }
  }

  .ant-dropdown-open {
    background: @dropdown-open;
  }
  
  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &__table-title {
    display: flex;
    align-items: center;
  }

  &__sort-btn {
    margin-left: 10px;
  }
}

