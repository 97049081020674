.teachers-review{
    &__teachers-card{
        border-radius: 8px;
        border: 1px solid #E4E7F2;
        width: 100%;
        margin: 20px 0;
        padding: 20px;
       
    }
    &__teacher-info{
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin-bottom: 24px;
    }
}
