@import "../../../../../../../theme/core.less";

.report-modal-course{
  &__subtitle{
    font-family: @font-headers;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: @text-primary-color;
  }
  &__table {
    &-title {
      display: flex;
      align-items: center;
    }
  }
  &__sort-btn{
    margin-left: 10px;
  }

  &__footer-actions{
    margin-top: 16px;
    display: flex;
  }

  &__footer-button:not(:last-child){
    margin-right: 16px;
  }
}

