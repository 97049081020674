@import '../../theme/core.less';

.button-v2 {
  &.ant-btn {
    height: @btn-v2-height;
    border-radius: @btn-v2-border-radius;
    font-family: @btn-v2-font-family;
    font-size: @btn-v2-font-size;
    font-weight: @btn-v2-font-weight;
    line-height: @btn-v2-line-height;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .media-tablet-only({
      font-weight: @tags-font-weight;
    });
    &:not(.ant-btn-block) {
      // если не во всю ширину родителя
      max-width: @btn-v2-max-width;
    }

    &.ant-btn-icon-only {
      width: 32px;
      height: 32px;
    }

    &.ant-btn.square-40 {
      width: 40px;
      height: 40px;
    }

    &.ant-btn-circle {
      border-radius: 50%;
    }

    .anticon {
      line-height: 0;
      font-size: 20px;
    }

    &-primary {
      background: linear-gradient(94.25deg, #007be0 -0.43%, #0058a0 54.69%);
      border-color: @btn-v2-color-primary;
      color: @btn-v2-color-secondary;

      &:hover {
        background: @primary-color;
        transition: none;
      }

      &[disabled] {
        color: @el-white-color;
        border-color: @el-disabled-color;
        background: @el-disabled-color;
      }
    }

    &-primary&-background-ghost {
      background: @btn-v2-color-secondary;
      border-color: @btn-v2-color-primary;
      color: @btn-v2-color-primary;

      &:hover {
        border-color: @el-hover-color;
        color: @el-hover-color;
      }

      &[disabled] {
        background: transparent;
        border-color: @el-disabled-color;
        color: @el-disabled-color;
      }
    }

    &-link {
      display: inline-block;
      height: fit-content;
      padding: 0;
      color: @btn-v2-color-primary;
    }
    &-text {
      color: @primary-color;
      background-color: transparent;
      padding: 0;
      height: auto;
      &:hover,
      &:focus {
        color: @el-hover-color;
        background-color: transparent;
      }
    }

    &__download {
      height: initial;
      width: initial;
      padding: initial;
      border: initial;
      background: initial;
      font-size: 18px;

      &:focus {
        color: @text-primary;
        background: @bg-primary;
        border-color: @bg-primary;
      }

      &:hover {
        color: @text-primary;
        background: @bg-primary;
        border-color: @bg-primary;
      }
    }
  }

  &--checkbox.ant-checkbox-wrapper {
    font-family: @btn-v2-font-family;
    font-size: @btn-v2-font-size;
    font-style: normal;
    font-weight: 400;
    line-height: @btn-v2-line-height;
    color: @bc-color;

    .ant-checkbox {
      &-inner {
        border-radius: 2px;
        border: 1px solid @login-separator-line;
        background-color: @btn-v2-color-secondary;
      }

      &-checked .ant-checkbox-inner {
        background-color: @btn-v2-color-primary;
      }
    }
  }
}

