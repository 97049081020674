@import "../../../../../../../theme/default.less";

.competence-modal{
  padding: 18px 0;

  &__info {
    padding: 16px 24px 44px;
    border: 1px solid @border-color;
    border-radius: 4px;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    &-label {
      margin-left: 16px;
    }
  }

  &__item{
    display: grid;
    grid-template-columns: 1fr 10px;
    grid-gap: 15px;
  }

  &__govement, &__digital {
    .ant-form-item {
      margin-top: 24px !important;
    }
  }
  &__digit-label {
    font-size: 16px!important;
    color: @text-primary-color;
  }
  &__add-more-btn{
    margin-top: 8px;
    margin-right: 25px;
    justify-content: flex-end;
    display: flex;
    grid-gap: 12px;
    color: @primary-color;
    align-items: center;
    >.anticon-plus{
      font-size: 12px;
    }
    >p{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__item + &__item {
    margin-top: 24px;
  }

  &__digital--hidden {
    display: none;
  }
  &__icon {
    &.anticon {
      color: @text-help-color;
      font-size: 25px;
      width: 50px;
      padding-top: 7px;
      text-align: center;
    }
  }
  &__questions-select{
    .ant-tag-close-icon{
      margin-left: 12px;
      svg{
        fill: #B7B9C0;
      }
    }
  }
}
//.competence-modal__digital {
//  color: red !important;
//  width: 20px !important;
//  .ant-form-item-label > label {
//    font-size: 16px !important;
//    color: rebeccapurple !important;
//  }
//}

