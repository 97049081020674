@import '../../theme/default.less';

.error-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  h1 {
    font-weight: 700;
    font-size: 320px;
    line-height: 120%;
    font-family: @font-headers;
    margin-bottom: 40px;
    color: @text-secondary;
    text-shadow: 0 4px 15px @col-shadow-text;
  }

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
    font-family: @font-headers;
    color: @text-dark-color;
  }

  p {
    text-align: center !important;
    color: @text-secondary-color;
    margin-bottom: 40px !important;
    font-family: @font-content;
  }
}

