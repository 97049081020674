@import "../../../../../../../../../../../../../../theme/default.less";

.quote-part {
  &__{
    &inputs-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      margin-top: 18px;
    }
  }
}
