@import "../../../../../theme/core.less";

.program-review{
    .ant-modal-content .ant-modal-body {
        padding:40px !important;
    }

    &__card{
        border-radius: 8px;
        border: 1px solid #E4E7F2;
        width: 100%;
        margin: 20px 0;
        padding: 20px;
        gap: 12px;
    }

    &__events{
        width: 70%;
    }
    &__events-item{
        display: flex;
        justify-items: flex-start;
        justify-content: space-between;
        margin-bottom: 24px;
    }

}

