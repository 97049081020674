@import "../../../../../../theme/core";

.education-form {
  &__button {
    margin-left: auto;
  }
  &__icon {
   color: @text-secondary !important;
   font-size: 23px;
  }
 
  .ant-dropdown-open {
    background: @dropdown-open;
  }

  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
}
