@import '../../theme/core.less';

.text-editor-v2 {

    &.quill {
        border-radius: 4px;
        border: none;
    }


    .ql-container {
        border: 1px solid @border-color;
        border-radius: 0 0 4px 4px;

        .ql-editor {
            min-height: 52px;
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: @text-primary-color;
                font-style: normal;
            }
        }
        .ql-blank::before {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: @el-gray-color;


        }
    }

    .ql-toolbar {
        background: @el-active-2-color;
        border: 1px solid @border-color;
        border-radius: 4px 4px 0 0;
    }
}

