@import "../../../../theme/core.less";

.create-mailing-list-modal{
  &__container{
    padding: 20px;
  }
  &__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: @font-headers;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    > h4{
      margin: 0;
      color: @text-primary-color;
    }
    > span{
      cursor: pointer;
      path {
        fill: @text-help-color;
      }
    }
  }
  &__pickers {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @el-lines-color;
  }
  &__label-form {
    color: @label-color;
    font-weight: 400;
    font-size: 14px;
  }
  &__form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
  }
}


