@import "../../theme/core.less";

.global-loader {
    width: 100%;
    font-size: 1.4rem;
    color: #000;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
        color: @text-help-color;
    }

    &__icon {
        color: @text-help-color;
        margin-left: 10px;
        margin-right: 10px;
    }
}

