.profiles-form-modal{

    &__footer{
        display: flex;
        justify-content: flex-end;
        column-gap: 20px;
        .ant-form-item {
          margin-bottom: 0;
        }
      }
  }

