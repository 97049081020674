.price-review {
  &__price-tab {
    display: flex !important;
    gap: 24px;
    flex-wrap: wrap;
    word-break: break-word;
    margin-bottom: 24px;
  }
}

