@import "../../../theme/core.less";
.general-table-template {
  &__search-panel {
    margin-top: 48px;
    display: flex;
    align-items: center;
  }

  &__table {
    margin-top: 30px;
  }
}

