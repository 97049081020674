@import "../../../../../../../theme/default.less";

.all-information{
  &__info{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-top: 18px;
    padding-bottom: 24px;
    border-bottom: 1px solid @el-lines-color;
    &-input{
      border-radius: 4px;
      height: 40px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: @text-primary-color;
      border-color: @border-color;
    }
  }
  &__date{
    margin-top: 24px;
    &-inputs{
      margin-top: 24px;
      max-width: 454px;
      width: 100%;
    }
    &-radios{
      span{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: @text-primary-color;
      }
    }
  }
  &__selects{
    display: grid;
    grid-gap: 24px;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid @el-lines-color;
    &-small{
      width: 100%;
      justify-content: space-between;
      display: flex;
      grid-gap: 24px;
      >div{
        width: 100%;
      }
    }
    &-hour{
      padding-top: 20px;
    }
  }
  &__special-selects{
    display: grid;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid @el-lines-color;

    padding-bottom: 24px;
    border-bottom: 1px solid @el-lines-color;


  }
  &__special-select-item + &__special-select-item {
    margin-top: 24px
  }
  &__special-select-item{
    display: grid;
    grid-template-columns: auto 10px;
    grid-gap: 15px;

    >.all-information__special-select-item-close{
      font-size: 12px;
      transform: rotate(45deg);
      line-height: 10px;
      height: 10px;
      align-self: flex-end;
      color: #BDBDBD;
      margin-bottom: 4px;
    }
  }
  &__add-more-btn{
    margin-top: 8px;
    margin-right: 25px;
    justify-self: flex-end;
    display: flex;
    grid-gap: 12px;
    color: @primary-color;
    align-items: center;
    >.anticon-plus{
      font-size: 10px;
    }
    >p{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__switch-btn{
    margin-top: 32px;
    display: flex;
    align-items: center;
    grid-gap: 16px;

    >p{
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: @text-primary-color;
    }
  }

  .ant-select-selection-item{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: @text-primary-color;
  }
  .ant-select-suffix{
    svg{
      fill: #909EBB;
    }
  }

  &__list-dates {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    & .ant-form-item:nth-child(even) {
      margin: 0 15px 0 24px;
    }
  }
}

