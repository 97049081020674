@import "../../../../theme/core.less";

.admin-view {
  &__main-tabs{
    font-family: @font-content;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &__list {
    margin-left: 44px !important;
  }
}

