@import "../../../../../../../../theme/core.less";
.form-blog {
  &__footer {
    display: flex;
    justify-content: space-between;

    .ant-form-item {
      margin: 0 0 0 16px;
    }
  }
}
