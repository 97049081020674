@import "../../../../../../theme/core.less";

.content-managers {
  &__select-box {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }

  &__select {
    width: 224px;
    margin-right: 24px !important;
  }

  &__select .ant-select-selector,
  .ant-picker.ant-picker-borderless {
    box-shadow: 0 4px 15px rgba(@col-black, 0.05) !important;
    border-radius: 10px !important;
    height: 36px !important;
    border: none !important;
  }

  &__date-piker {
    width: 187px;
    margin-right: 24px !important;
  }

  &__select .ant-select-selection-placeholder,
  &__date-piker input::placeholder {
    color: @col-text-select !important;
  }

  &__table-title {
    display: flex;
    align-items: center;
  }

  &__sort-btn {
    margin-left: 10px;
  }

  &__btn-clear {
    color: @text-secondary-color !important;

    &--hidden {
      display: none !important;
    }
  }

  &__dropdown-icon {
    cursor: pointer;
  }

  &__btn-add {
    margin-left: auto;
  }
}

