@import '../../../../../../theme/default';

.promo {
  .ant-dropdown-open {
    background: @dropdown-open;
  }

  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
  &__table-title {
    display: flex;
    align-items: center;
  }

  &__sort-btn {
    margin-left: 8px;
  }

  &__add {
    margin-left: auto;
  }
}
.modal-v2 .ant-modal-body {
  margin-bottom: 0 !important;
}


