@import "../../../../theme/core.less";

.remove-role-modal{
  &__container{
    padding: 20px;
  }
  &__box{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__text{
    font-family: @font-headers;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-top: 40px;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    & > button:not(:last-child) {
      margin-right: 24px;
    }
  }
}

