@import "../../../../../../../theme/default.less";

.modal-add-criteria {

  &__footer {
    display: flex;
    justify-content: flex-end;

    & > button:not(:last-child) {
      margin-right: 16px;
    }
  }
  &__input {
    & .ant-radio-inner::after {
      transform: scale(1) !important;
    }
  }
}

