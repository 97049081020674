.modal-price {
  &__head-inputs {
    display: flex;
    margin-top: 24px;
  }
  &__info-input:not(:last-child) {
    margin-right: 24px;

  }
  &__info-input {
    input {
      width: 222px;
    }
  }
  &__bitrix-input{
    margin-top: 20px !important;
    input {
      width: 222px;
    }
  }
  &__textarea {
    margin-top: 24px !important;
    width: 432px;
  }
}
