@import "../../../../../../../../theme/core";

.content-manager-report {
  &__status {
    &--published {
      color: @status-green-color;
    }
    &--moderate {
      color: @status-warning-color;
    }
    color: @el-disabled-color;
  }
}
