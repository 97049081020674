@import "../../../theme/core.less";

.report-modal{
  &__container{
    padding: 20px;
  }
  &__subtitle{
    font-family: @font-headers;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: @text-primary-color;
  }
  &__footer{
    display: flex;
    column-gap: 16px;
  }
}

