@import "../../../../theme/default.less";

.modal-create-course {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  & .ant-form-item-label {
    height: 22px;

    & label {
      vertical-align: inherit;
    }
  }

  .ant-modal-content {
    padding: 0 40px;
    overflow: auto;
    &::-webkit-scrollbar{
      width: 3px;
    }
    &::-webkit-scrollbar-track{
      width: 3px;
      background: white;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 3px;
      background: @scroll-color;
    }

    .ant-modal-close {
      top: 40px;
      right: 40px;
      line-height: 18px;

      span {
        top: 40px;
        right: 40px;
        line-height: 18px;
        font-size: 18px;
      }
    }

    .ant-modal-header {
      height: 88px !important;
      padding: 40px 0 20px 0 !important;
      border-bottom: 1px solid @el-lines-color;
    }

    >div {
      padding: 0;
    }
  }

  &__modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: @text-primary-color;
    font-family: @font-headers;
  }

  &__header-close-btn {
    cursor: pointer;
  }

  &__body {
    padding: 28px 0 32px 0;
  }

  &__tabs {
    .ant-tabs-nav {

      &::before {
        display: none;
      }

      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }

    .ant-tabs-tab-btn {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: @text-primary-color;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    cursor: pointer;
  }
  .ant-input {
    border-radius: 4px;
    max-height: 52px;
  }
  .textarea.ant-input.ant-input-lg{
    padding: 10px 16px;
  }

  .all-information__special-select-item-close {
    font-size: 12px;
    transform: rotate(45deg);
    line-height: 10px;
    height: 10px;
    align-self: flex-end;
    color: #BDBDBD;
    margin-bottom: 4px;
  }

  .ant-picker-input {
    input {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: @text-primary-color;
    }
  }

  .ant-modal-body {
    min-height: 80%;
  }

  .ant-input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: @font-content;
  }

  .ant-input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: @font-content;
    color: @text-help-color;
  }

  .ant-picker-input {
    input::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      font-family: @font-content;
      color: @text-help-color;
    }
  }
  .ant-select-selection-placeholder{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: @font-content;
    color: @text-help-color;
  }
  .ant-tabs-nav-list{
    padding: 0;
  }
  .ant-tabs-nav-more{
    display: none;
  }
}

.modal-global-style{
  &__container{
    padding-top: 18px;
    display: grid;
    grid-gap: 24px;
  }
  &__item{
    border: 1px solid @border-color;
    padding: 16px 24px;
    border-radius: 4px;
  }
  &__header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

  }
  &__header-drag{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-self: center;
    grid-template-rows: 3px 3px;
    grid-row-gap: 4px;
    width: 15px;
    height: 10px;
  }
  &__dot{
    width: 3px;
    height: 3px;
    background-color: @text-help-color;
    border-radius: 3px;
    justify-self: center;
  }
  &__geader-icons{
    justify-self: flex-end;
    display: flex;
    grid-gap: 25px;
    align-items: center;
  }

  &__icon {
    cursor: pointer;
  }

  &__inputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    >div{
      width: 100%;
    }
  }
  &__item-close {
    font-size: 12px;
    transform: rotate(45deg);
    line-height: 10px;
    height: 10px;
    align-self: flex-end;
    color: #BDBDBD;
    margin-bottom: 4px;
    svg{
      fill: #BDBDBD;
    }
  }
}

