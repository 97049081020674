@import "../../theme/core.less";
.tag{
  font-family: @font-content;
  font-weight: 500;
  font-size: 16px;
  width: fit-content;
  padding: 0 10px;
  height: 24px;
  border-radius: 20px;
  margin-bottom: 10px;
  line-height: 24px;
  &__primary{
    color: @text-primary-color;
  }
  &__secondary{
    color: @text-secondary;
  }
}

