@import "./theme/default.less";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  color: inherit !important;
}
p {
  margin-bottom: 0 !important;
}
.ant-message-notice-content  {
  padding: 0 !important;

  .ant-message-custom-content {
    width: fit-content !important;
    padding: 10px 16px;
  }
  & .ant-message-success {
    background: @modal-success-o-60;
  }

  & .ant-message-error {
    background: @message-error-bg;
  }

  & .ant-message-warning {
    background: @message-warning-bg;
  }

  & .anticon-check {
    color: @el-white-color !important;
  }

  &  span:last-child {
    font-family: @font-headers;
    color: @text-secondary;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
}

.ant-form-item-label {
  padding: 0 !important;
  margin-bottom: 4px;
  & > label {
    font-weight: 400;
    line-height: 20px !important;
    color: @label-color !important;
    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      margin: 0 !important;
      position: absolute;
      right: -10px;
      top: -4px;
      display: inline-block;
      content: '\22C6';
      font-size: 14px;
      color: #1890ff;
    }
  }
}
.position-inside {
  & ol, ul {
    list-style-position: inside;
  }
}
.ant-form-item-required {
  &::before {
    content: '' !important;
  }
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(1) !important;
}

