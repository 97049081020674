@import '../../../../theme/core.less';

.file-name-area {
    background-color: @el-hover-2-color;
    color:@primary-color;
    font-size: 16px;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
    &__fileName{
        padding-left: 10px;
    }
    &__icon {
        font-size: 20px;
        color: @primary-color;
    }
}

