@import "../../../../theme/core.less";

.wrapper-tabs {
  max-width: 1478px;
  width: 100%;
  margin: 48px auto 40px;
  background: @bg-secondary;
  border-radius: 16px;
  padding: 40px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: @font-headers;
    font-style: normal;
    margin-bottom: 38px;

    > h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: @text-primary-color;
    }

    > span {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: @text-help-color;
    }
  }

  &__tabs {
    font-family: @font-headers;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
  }
}

