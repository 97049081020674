@import "../../../../../../theme/default.less";
.modal-admin-course {
  &__modal-header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    text-align: center;
  }

  &__image {
    text-align: center;
  }

  &__text {
    margin: 40px auto 0;
    max-width: 400px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    & .modal-cancel-btn {
      margin-right: 16px;
    }
  }
}

