@import "../../../../theme/core.less";

.special-file-upload {
  position: relative;

  &__upload-text {
    display: flex;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 20px;
      color: @text-secondary-color;
    }
  }

  &__add-file {
    position: absolute !important;
    box-shadow: none !important;
    border: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: @brand-prime !important;
    right: 0;
    bottom: 10px;

    &--delete {
      display: none !important;
    }
  }

  .ant-upload-list-text .ant-upload-span, .ant-upload-list-picture .ant-upload-span {
    width: fit-content;
  }

  .ant-upload-list-item {
    left: 477px;
    bottom: 46px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: @brand-prime !important;
    width: fit-content;
  }

  .anticon-file-image {
    color: @primary-color !important;
  }

  .ant-upload-span {
    width: fit-content;
  }

  .ant-upload-list-text-container, .ant-upload-list-picture-container {
    height: 0;
  }

  .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: none !important;
  }
  &__error {
    color: @status-error-color;
  }
}

