@import '../../../../../../theme/default';

.reviews {
  margin-top: 48px;
  position: relative;

  &__button {
    margin-left: auto;
  }

  &__menu {
    padding: 12px 16px;
    list-style: none;
    background: @bg-secondary;
  }

  &__menu-item > button {
    color: @text-primary-color;
    cursor: pointer;
    padding: 0;
    background: transparent;

    &:hover {
      color: @text-link-color;
      background: transparent;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__menu-item--delete > button:hover {
    color: @status-error-color;
  }

  .ant-dropdown-open {
    background: @dropdown-open;
  }

  &__dropdown {
    border-radius: 4px;
    cursor: pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }

    &__status {
      color: @el-disabled-color;
  
      &--active {
        color: @status-green-color;
      }
  }
}

