.competencies-review{
    &__competencies-item{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .ant-divider-horizontal{
        width: 912px !important;
    }
    &__anotherStatePrograms{
        border-radius: 8px;
        border: 1px solid #E4E7F2;
        width: 100%;
        margin: 20px 0;
        padding: 20px;
    }
    &__digitalProfessions{
        border-radius: 8px;
        border: 1px solid #E4E7F2;
        width: 100%;
        margin: 20px 0;
        padding: 20px;
    }
    &__banners{
        p{
          font-size: 16px;
        }
    }
}
