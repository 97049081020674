@import "../../../../../theme/default.less";

.modal-create-course-footer{
  margin-top: 32px;
  border-top: 1px solid @el-lines-color;
  padding: 16px 0 40px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &__btn:not(:first-child) {
    margin: 0 0 0 16px;
  }
}

