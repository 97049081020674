@import '../../theme/core.less';

.modal-v2 {

  &__modal-header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-family: @font-headers;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
    color: @text-primary-color;
    padding: 0;
    margin: 0;
  }

  &__close-btn-icon {
    font-size: 30px !important;
    color: @el-gray-color !important;
    transform: rotate(45deg);
  }

  .ant-modal {
    &-content {
      padding: 32px;
    }
    &-body {
      margin: 24px 0;
    }

    &-header {
      padding: 0 0 16px;
    }


    &-body {
      padding: 0;
    }

    &-footer {
      margin-top: 24px;
      padding: 24px 0 0;
    }
  }
}

// prevent body width change
// from opened ant modal
body.ant-scrolling-effect {
  width: 100% !important;
}

