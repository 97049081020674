@import "../../../../../../../theme/default.less";

.modal-review-education-form {
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: none;
 
  &__icon {
    border: 2px solid @primary-color;
    width: 94px;
    height: 94px;
    color: @text-secondary;
    font-size: 50px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
