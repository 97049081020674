.file-crop {
  .ant-modal-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 30%);
  }
}

